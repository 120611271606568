<template>
  <div>
    <Modal :options="{width: '40vw', type: 'danger', close: true}" @close="$emit('close')">
      <div class="title">Rechazar comprobante</div>
      <div class="body">
        <div class="row form-group">
          <label for="razon_rechazo" class="col-form-label col-sm-3">Razón de rechazo</label>
          <div class="col-sm-9"><textarea v-model="razon_cancelacion" name="razon_rechazo" id="razon_rechazo" cols="30" rows="10" class="form-control"></textarea></div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="rechazar_componente">Rechazar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'

import api from '@/apps/tesoreria/api/viaticos'

export default {
  components: {
    Modal
  },
  props: {
    comprobante: {
      type: Object,
      required: true,
      default() {
        return {
          id: null
        }
      }
    }
  },
  data() {
    return {
      razon_cancelacion: null
    }
  },
  methods: {
    async rechazar_componente() {
      try {
        if (!this.razon_cancelacion)
          return this.$helper.showMessage('Error','Debes definir la razón del rechazo','error','alert');

          await api.editar_comprobante(this.comprobante.viatico_id, this.comprobante.id, {razon_cancelacion: this.razon_cancelacion, estatus: 3});

          this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>