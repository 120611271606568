<template>
  <div>
    <Modal :options="{width: '50vw',close: true}" @close="$emit('close')">
      <div class="title">Comprobante ${{ $helper.moneyFormat(comprobante.monto) }}</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-8">
            <div class="row form-group">
              <h3 class="col-sm-12">Información general</h3>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-4">Monto total</label>
              <div class="col-sm-8">
                <div class="form-control">${{ $helper.moneyFormat(comprobante.monto) }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-4">Tipo de comprobante</label>
              <div class="col-sm-8">
                <div class="form-control">{{ tipo_comprobante }}</div>
              </div>
            </div>
            <div v-if="(comprobante.estatus == 3)" class="row form-group">
              <label for="" class="col-form-label col-sm-4">Razón de rechazo</label>
              <div class="col-sm-8">
                <div class="form-control">{{ comprobante.razon_cancelacion }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-4">Estatus</label>
              <div class="col-sm-8">
                <div class="form-control">{{ estatus }}</div>
              </div>
            </div>
            <div class="row form-group">
              <h3 class="col-sm-12">Conceptos</h3>
            </div>

            <table class="factura_conceptos">
              <thead>
                <tr>
                  <th>Concepto</th>
                  <th>Monto</th>
                  <th v-if="comprobante.tipo == 'factura'">Iva</th>
                  <th v-if="comprobante.tipo == 'factura'">Tipo factor</th>
                  <th v-if="comprobante.tipo == 'factura'">Tipo impuesto</th>
                  <th v-if="comprobante.tipo == 'factura'">Tasa o cuota</th>
                </tr>
              </thead>
              <tbody>
                
                <!-- <tr v-for="concepto in comprobante.conceptos" :key="concepto.id" >
                  <td>{{ concepto.concepto }}</td>
                  <td>${{ $helper.moneyFormat(concepto.monto) }}</td>
                  <td v-if="comprobante.tipo == 'factura'">${{ $helper.moneyFormat(concepto.iva) }}</td>
                  <td v-if="comprobante.tipo == 'factura'" class="text-center">{{ concepto.tipo_factor }}</td>
                  <td v-if="comprobante.tipo == 'factura'" class="text-center">{{ concepto.tipo_impuesto }}</td>
                  <td v-if="comprobante.tipo == 'factura'" class="text-center">{{ concepto.tasa_cuota }}</td>
                </tr> -->

                <template v-if="comprobante.tipo == 'factura'">
                    <template v-for="concepto in comprobante.conceptos">
                      <tr v-for="impuesto in concepto.impuestos" :key="impuesto.id">
                        <td>{{ concepto.concepto }}</td>
                        <td>${{ $helper.moneyFormat(concepto.monto) }}</td>                        
                        <td>${{ $helper.moneyFormat(impuesto.importe) }}</td>
                        <td class="text-center">{{ ( !impuesto.tipo_factor ? 'N/A' : impuesto.tipo_factor )}}</td>
                        <td class="text-center">{{ (!impuesto.impuesto ? 'N/A' : impuesto.impuesto ) }}</td>
                        <td class="text-center">{{ (!impuesto.tasa_cuota ? 'N/A' : impuesto.tasa_cuota ) }}</td>
                      </tr>
                    </template>
                </template>
                <template v-else>
                  <tr v-for="concepto in comprobante.conceptos" :key="concepto.id" >
                    <td>{{ concepto.concepto }}</td>
                    <td>${{ $helper.moneyFormat(concepto.monto) }}</td>
                  </tr>
                </template>

              </tbody>
            </table>            

            <Comentario :viatico="viatico" :comprobante="comprobante" @update="$emit('update')" />
          </div>
          <div class="col-sm-4">
            <div class="row">
              <h3 class="col-sm-12">Documentos</h3>
            </div>
            <div class="row">
              <div v-if="['jpg','jpeg','png','bmp'].indexOf(documento.extension) != -1" class="col-sm-12">
                <img class="documento_img mb-3" :src="contenido" />
                <button class="btn btn-secondary" @click="descargar_documento">Descargar Imágen</button>
              </div>
              <div v-else class="col-sm-12">
                <embed class="documento_pdf" :src="contenido" type="application/pdf" />
                <button class="btn btn-secondary" @click="descargar_documento">Descargar PDF</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button v-if="estatus_validos(viatico.estatus, ['ESTATUS_CONCILIACION_ICONSISTENCIA','ESTATUS_VALIDANDO_COMPROBANTES']) && (comprobante.estatus == 1)" class="btn btn-success mr-2" @click="actualizar_comprobante(2)">Aprobar</button>
            <button v-if="estatus_validos(viatico.estatus, ['ESTATUS_CONCILIACION_ICONSISTENCIA','ESTATUS_VALIDANDO_COMPROBANTES']) && (comprobante.estatus == 1)" class="btn btn-danger mr-2" @click="(modal_rechazar=true)">Rechazar</button>
            <button class="btn btn-primary mr-2" @click="$emit('close')">Cerrar</button>
          </div>
        </div>
      </div>
    </Modal>
    
    <Rechazar v-if="modal_rechazar" :comprobante="comprobante" @update="rechazo_comprobante" @close="(modal_rechazar=false)" />
  </div>
</template>

<script>
import Modal from '@/components/Modal'

import Rechazar from './Revisar/Rechazar'
import Comentario from './Revisar/Comentario'

import config from '@/apps/tesoreria/configuracion/viaticos'

import api from '@/apps/tesoreria/api/viaticos'

export default {
  components: {
    Modal, Rechazar, Comentario
  }
  ,props: {
    viatico: {
      type: Object,
      default() {
        return {
          id: null
        }
      }
    },
    comprobante: {
      type: Object,
      required: true,
      default() {
        return {
          id: null,
          comentarios: null
        }
      }
    }
    ,catalogo: {
      type: Array,
      default() {
        return []
      }
    }
  }
  ,data() {
    return {
      binario: null,
      contenido: null,
      comentario: null,
      pagina_actual: 1,
      por_pagina: 3,
      a_mostrar: 3,
      modal_rechazar: false
    }
  }
  ,mounted() {
    this.visualizar_imagen();
  }
  ,methods: {
    async visualizar_imagen() {
      try {
        api.visualizar_documento(this.comprobante.viatico_id, this.documento.id)
        .then(res => {
          this.binario = res.data;
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          )

          this.contenido = 'data:'+this.documento.mime+';base64,'+base64;
        })
        // this.$log.info('comprobante prop ', this.comprobante);

      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,descargar_documento() {
      this.$helper.descargar_documento(this.binario, this.documento.nombre_original);
    }
    ,eliminar_comprobante() {
      this.$emit('close');
      this.$emit('eliminar',this.comprobante);
    }
    ,async actualizar_comprobante(estatus) {
      try {
        await api.editar_comprobante(this.viatico.id, this.comprobante.id, {estatus});

        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,rechazo_comprobante() {
      this.modal_rechazar = false;
      this.$emit('update');
    },
    estatus_validos(valor, estatus) {
      this.$log.info('estatus', config.viaticos.estatus_validos(valor, estatus));
      return config.viaticos.estatus_validos(valor, estatus);
    }
  }
  ,computed: {
    tipo_comprobante() {
      return this.comprobante.tipo == 'nota' ? 'Nota' : 'Factura';
    }
    ,documento() {
      if (this.comprobante.tipo == 'nota') 
        return this.comprobante.documentos[0];
      else {
        let documento = null;

        for(let i=0; i<this.comprobante.documentos.length; i++) {
          if (this.comprobante.documentos[i].extension == 'pdf')
            documento = this.comprobante.documentos[i];
        }

        return documento;
      }
    }
    ,estatus() {
      switch(this.comprobante.estatus) {
        case 1:
          return 'Activo';
        case 2:
          return 'Aprobado';
        case 3:
          return 'Rechazado';
      }
    }
  }
}
</script>

<style lang="scss">
.documento_img {
  width: 100%;
}

.documento_pdf {
  width: 100%;
  height: 400px;
}

.factura_conceptos {
  width: 100%;

  thead {
    tr {
      th {
        background-color: #3b3b3b;
        padding: 5px 10px;
      }
    }
  }
}
</style>