<template>
  <div>
    <Modal :options="{width: '50vw', close:true}" @close="$emit('close')">
      <div class="title">Evidencia ${{ $helper.moneyFormat(evidencia.monto) }}</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-8">
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-3">Monto</label>
              <div class="col-sm-9">
                <div class="form-control">${{ $helper.moneyFormat(evidencia.monto) }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-3">Fecha deposito</label>
              <div class="col-sm-9">
                <div class="form-control">{{ evidencia.fecha_deposito }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-3">Tipo de pago</label>
              <div class="col-sm-9">
                <div class="form-control">{{ evidencia.tipo == 1 ? 'Depósito' : 'Descuento de nómina' }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-3">Estatus</label>
              <div class="col-sm-9">
                <div class="form-control">{{ estatus(evidencia.estatus) }}</div>
              </div>
            </div>
            <div v-if="evidencia.razon_rechazo" class="row form-group">
              <label for="" class="col-form-label col-sm-3">Razón rechazo</label>
              <div class="col-sm-9">
                <div class="form-control">{{ evidencia.razon_rechazo }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div v-if="['jpg','jpeg','bmp','png'].indexOf(evidencia.documentos[0].extension) != -1" class="col-sm-12">
                <img class="show-document mb-2" :src="archivo" alt="Documento"><br/>
                <button class="btn btn-secondary" @click="descargar_archivo">Descargar imágen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button v-if="evidencia.estatus == 1" class="btn btn-success mr-2" @click="actualizar_estatus(2)">Aprobar</button>
            <button v-if="evidencia.estatus == 1" class="btn btn-danger mr-2" @click="modal_rechazar=true">Rechazar</button>
            <button class="btn btn-primary" @click="$emit('close')">Cerrar</button>
          </div>
        </div>
      </div>
    </Modal>
    
    <Modal v-if="modal_rechazar" :options="{width: '40vw',type:'danger'}" @close="modal_rechazar=false">
      <div class="title">Rechazar evidencia</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12">
            <label for="razon_rechazo">Razón de rechazo</label>
            <textarea v-model="razon_rechazo" name="razon_rechazo" id="razon_rechazo" cols="30" rows="10" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="actualizar_estatus(100)">Rechazar evidencia</button>
            <button class="btn btn-danger" @click="modal_rechazar=false">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/tesoreria/api/viaticos';
import config from '@/apps/tesoreria/configuracion/viaticos'

export default {
  watch: {
  },
  components: {
    Modal,
  },
  props: {
    viatico: {
      type: Object,
      required: true
    }
    ,evidencia: {
      type: Object,
      required: true
    },
    catalogo:{
      type: Array,
      default(){
        return{
          catalogo:[]
        }
      }
    }
  },
  data() {
    return {
      binario: null,
      archivo: null,
      razon_rechazo: null,
      modal_rechazar: false
    }
  },
  mounted() {
    this.descargar_mostrar_archivo();
    this.$log.info('catalogos', config)
  },
  methods: {
    estatus(estatus) {
      switch(estatus) {
        case 1:
          return 'Activo';
        case 2:
          return 'Aprobado';
        case 100:
          return 'Rechazado';
      }
    }
    ,async descargar_mostrar_archivo() {
      try {
        this.binario = (await api.descargar_evidencia_documentos(this.viatico.id, this.evidencia.id, this.evidencia.documentos[0].id)).data;
        let base64 = btoa(
          new Uint8Array(this.binario).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );

        this.archivo = 'data:'+this.evidencia.documentos[0].mime+';base64,'+base64;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,descargar_archivo() {
      this.$helper.descargar_documento(this.binario, this.evidencia.documentos[0].nombre_original);
    }
    ,async actualizar_estatus(estatus) {
      try {
        let payload = this.$helper.clone(this.evidencia);
        payload.estatus = estatus;

        if (this.razon_rechazo)
          payload.razon_rechazo = this.razon_rechazo;

        await api.editar_evidencia(this.viatico.id, payload.id, payload);

        if(estatus == config.viaticos.evidencias.ESTATUS_RECHAZADO)
          this.viatico.estatus = config.viaticos.constantes.ESTATUS_RECHAZO_COBRO_MONTO_SOBRANTE;

        this.viatico.estatus = config.viaticos.constantes.ESTATUS_CERRADO;
        await api.actualizar_estatus(this.viatico.id, this.viatico);

        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.show-document {
  width: 100%;
}
</style>