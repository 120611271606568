<template>
  <Modal :options="{width: '30vw', type: 'danger'}">
    <div class="title">Conciliación</div>
    <div class="body">
      <div class="row">
        <div class="col-sm-12 text-center">
          <p>Al marcar para conciliación tendrás solo 15 días para subir tus documentos que comprueben el viatico.</p>
          <p>¿Deseas continuar?</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-primary mr-2" @click="$emit('aceptar')">Aceptar</button>
          <button class="btn btn-danger" @click="$emit('cancelar')">Cancelar</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  }
}
</script>