<template>
  <div>
    <div class="row mt-4">
      <div class="col-sm-8 viatico-titulo">Comprobantes</div>
    </div>

    <div class="content comprobantes">
      <div class="row comprobante">
        <div class="col-sm-3 campo">Monto</div>
        <div class="col-sm-5 campo">Números de conceptos</div>
        <div class="col-sm-2 campo">Estatus</div>
        <div class="col-sm-2 campo">Opciones</div>
      </div>
      <template v-if="viatico.comprobantes.length > 0">
      <div v-for="comprobante in viatico.comprobantes" :key="comprobante.id" class="row comprobante">
        <div class="col-sm-3 campo">${{ $helper.moneyFormat(comprobante.monto) }}</div>
        <div class="col-sm-5 campo text-left">{{ comprobante.conceptos.length }}</div>
        <div class="col-sm-2 campo text-left">{{ mapear_estatus(comprobante.estatus) }}</div>
        <div class="col-sm-2 campo">
          <i class="fa-regular fa-folder-open mr-2" title="Revisar comprobante" @click="revisar_comprobante(comprobante)"></i>
        </div>
      </div>
      </template>
      <div v-else class="row comprobante">
        <div class="col-sm-12">No hay comprobantes</div>
      </div>
    </div>

    <Agregar v-if="modal_documento" :viatico="viatico" @close="modal_documento=false" @update="actualizar" />
    <Revisar v-if="modal_revisar" :viatico="viatico" :comprobante="comprobante" @close="modal_revisar=false" @update="$emit('update')" @eliminar="eliminar_comprobante" />
  </div>
</template>

<script>
import Agregar from './Comprobante/Agregar'
import Revisar from './Comprobante/Revisar'

import api from '@/apps/constelacion/api/tesoreria'

export default {
  components: {
    Agregar, Revisar
  },
  props: {
    viatico: {
      type: Object
      ,default() {
        return {
          monto: "0",
          conceptos: [],
          comprobantes: [],
          comprobante: null
        }
      }
    }
    ,catalogo: {
      type: Array,
      default() {
        return []
      }
    }
  }
  ,data() {
    return {
      comprobante: null,
      modal_documento: false,
      modal_revisar: false,
      modal_rechazar: false
    }
  }
  ,methods: {
    revisar_comprobante(comprobante) {
      this.comprobante = comprobante;
      this.modal_revisar = true;
    },
    actualizar() {
      this.modal_documento = false;
      this.$emit('update');
    },
    async eliminar_comprobante(comprobante) {
      try {
        let res = (await api.eliminar_comprobante(this.viatico.id, comprobante.id)).data;
        this.$emit('update');
      }catch(e) {
        this.$log.info('error', e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    mapear_estatus(estatus) {
      switch(estatus) {
        case 1:
          return 'Pendiente';
        case 2:
          return 'Validado';
        default:
          return 'Rechazado';
      }
    }
  }
  ,computed: {
    monto_viatico() {
      return parseFloat(this.viatico.monto);
    }
  },
  watch: {
    viatico: {
      handler: function(val) {
        if (this.comprobante) {
          this.viatico.comprobantes.forEach(tmp => {
            if (tmp.id == this.comprobante.id)
              this.comprobante = tmp;
          })
        }
      }
      ,deep: true
    }
  }
}
</script>

<style lang="scss">
.comprobantes {
  .comprobante {
    border-bottom: solid 1px #e6e6e6;

    .campo {
      padding: 5px 10px;
    }
  }
}
</style>